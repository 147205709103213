<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="70%"
    top="5vh"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-tabs v-model="currentTab" type="card">
        <el-tab-pane :label="$t('developer.information')" name="first">
          <el-form
            ref="formValidate"
            class="form"
            label-width="100px"
            :model="developer"
            :rules="ruleValidate">
            <el-form-item :label="$t('developer.name')" prop="name">
              <el-input v-model="developer.name" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('developer.openType')" prop="openType" :rules="$rule.notNull">
              <el-select v-model="developer.openType" style="width: 100%">
                <el-option label="mqtt" :value="1"></el-option>
                <el-option label="http" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="developer.openType === 2"
              :label="$t('developer.appKey')"
              prop="appKey"
              :rules="$rule.notNull">
              <el-input v-model="developer.appKey" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item
              v-if="developer.openType === 2"
              :label="$t('developer.appSecret')"
              prop="appSecret"
              :rules="$rule.notNull">
              <el-input v-model="developer.appSecret" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item
              v-if="developer.openType === 1"
              :label="$t('developer.mqtt')"
              prop="mqtt"
              :rules="$rule.notNull">
              <el-input v-model="developer.mqtt" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item prop="username" :label="$l('subPlatform.userName','用户')" :rules="$rule.notNull">
              <el-input v-model.trim="developer.username" :placeholder="$t('common.pleaseSelect')" readonly>
                <el-button slot="append" icon="el-icon-search" @click="$refs.userSelect.open()"></el-button>
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('developer.status')" prop="status">
              <el-radio-group v-model="developer.status">
                <el-radio class="radio" :label="0">{{$t("developer.close")}}</el-radio>
                <el-radio class="radio" :label="1">{{$t("developer.activate")}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <user-select ref="userSelect" @select="onUserSelect"></user-select>
        </el-tab-pane>
        <el-tab-pane v-if="developer.openType === 2" :label="$t('developer.Subscription')" name="second">
          <el-table :data="developerCallbackList">
            <el-table-column prop="type" :label="$t('developer.type')">
              <template slot-scope="scope">
                <span v-if="scope.row.type === 'elevatorRegister'">
                  电梯信息数据注册推送基地址
                </span>
                <span v-else-if="scope.row.type === 'statusNotice'">
                  订阅电梯状态数据推送基地址
                </span>
                <span v-else-if="scope.row.type === 'playUrl'">
                  获取最新音视频通话地址推送基地址
                </span>
                <span v-else-if="scope.row.type === 'setTalk'">
                  主动设置对讲号码推送基地址
                </span>
                <span v-else-if="scope.row.type === 'getToken'">
                  获取Token
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="url" :label="$t('developer.baseUrl')">
              <template slot-scope="scope">
                <el-input v-model="scope.row.url"></el-input>
              </template>
            </el-table-column>
          </el-table>
          <el-divider content-position="left">获取Token所需参数</el-divider>
          <div>
            <el-form
              ref="formModel"
              class="form"
              :model="developer.tokenModel"
              label-width="100px">
              <div class="vm-separate">
                <el-form-item label="appkey" prop="appkey">
                  <el-input v-model="developer.tokenModel.appkey" :placeholder="$t('common.pleaseEnter')"></el-input>
                </el-form-item>
                <el-form-item label="appSecret" prop="appSecret">
                  <el-input
                    v-model.trim="developer.tokenModel.appSecret"
                    :placeholder="$t('common.pleaseEnter')"></el-input>
                </el-form-item>
              </div>
              <div class="vm-separate">
                <el-form-item label="userName" prop="userName">
                  <el-input
                    v-model.trim="developer.tokenModel.userName"
                    :placeholder="$t('common.pleaseEnter')"></el-input>
                </el-form-item>
                <el-form-item label="password" prop="password">
                  <el-input
                    v-model.trim="developer.tokenModel.password"
                    :placeholder="$t('common.pleaseEnter')"></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" :loading="submitLoading" @click="handleSubmit">
        {{$t("common.save")}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  const moduleName = "developers";
  import userSelect from "@/views/user/UserSelect.vue";

  export default {
    components: {userSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        saveDisabled: false,
        currentTab: "first",
        developerCallbackList: [
          {id: 0, type: "elevatorRegister", url: ""},
          {id: 0, type: "statusNotice", url: ""},
          {id: 0, type: "playUrl", url: ""},
          {id: 0, type: "setTalk", url: ""},
          {id: 0, type: "getToken", url: ""},
        ],
        developer: {
          id: 0,
          name: "",
          address: "",
          contact: "",
          mobile: "",
          appKey: "",
          appSecret: "",
          openType: 1,
          userId: 0,
          username: "",
          status: 0,
          subscriptionService: "",
          subscriptionServiceList: [],
          developerCallbacks: [],
          tokenModel: {
            appkey: "",
            appSecret: "",
            userName: "",
            password: "",
          },
        },
        ruleValidate: {
          appKey: [{required: true, min: 1, message: this.$t("developer.tip.no"), trigger: "blur"}],
          appSecret: [
            {
              required: true,
              min: 8,
              max: 8,
              pattern: /^[A-Za-z0-9]{8}$/,
              message: this.$t("developer.tip.appSecret"),
              trigger: "blur",
            },
          ],
          name: [{required: true, min: 1, message: this.$t("developer.tip.name"), trigger: "blur"}],
          mobile: [{pattern: /^1[0-9]{10}$/, message: this.$t("developer.tip.mobile"), trigger: "blur"}],
        },
      };
    },
    computed: {
      title() {
        return (this.developer.id === 0 ? this.$t("common.add") : this.$t("common.edit")) + " " + this.$t("developer.developer");
      },
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        this.developer.id = id;
        if (id > 0) {
          this.getData();
        }
      },
      onUserSelect(row) {
        this.developer.userId = row.id;
        this.developer.username = row.name;
      },
      getData() {
        this.contentLoading = true;
        this.saveDisabled = true;
        this.$api
          .getById(moduleName, this.developer.id)
          .then((res) => {
            this.developer = res.data;
            if (res.data.tokenModel) {
              this.developer.tokenModel = res.data.tokenModel;
            } else {
              this.developer.tokenModel = {
                appkey: "",
                appSecret: "",
                userName: "",
                password: "",
              };
            }
            for (let item of this.developer.developerCallbacks) {
              if (item.type === "elevatorRegister") {
                this.developerCallbackList[0].url = item.url;
                this.developerCallbackList[0].id = item.id;
              } else if (item.type === "statusNotice") {
                this.developerCallbackList[1].url = item.url;
                this.developerCallbackList[1].id = item.id;
              } else if (item.type === "playUrl") {
                this.developerCallbackList[2].url = item.url;
                this.developerCallbackList[2].id = item.id;
              } else if (item.type === "setTalk") {
                this.developerCallbackList[3].url = item.url;
                this.developerCallbackList[3].id = item.id;
              } else if (item.type === "getToken") {
                this.developerCallbackList[4].url = item.url;
                this.developerCallbackList[4].id = item.id;
              }
            }
            this.contentLoading = false;
            this.saveDisabled = false;
          })
          .catch((error) => {
            this.contentLoading = false;
            if (error.response) {
              this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
            }
          });
      },
      onDialogClose() {
        this.$refs.formValidate.resetFields();
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            this.developer.developerCallbacks = this.developerCallbackList;
            this.$api
              .save(moduleName, this.developer)
              .then((res) => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success", this.developer.id);
                this.$message.success(this.$t("common.tip.saveSuccess"));
              })
              .catch((error) => {
                this.submitLoading = false;
                this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
              });
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped></style>
