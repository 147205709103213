<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="developers">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button v-if="editAuth" class="addBtn" @click="$refs.editPage.open(0)">
          {{ $l("elevatorNetApply.add", "+ 新增") }}
        </el-button>
      </template>
      <el-table-column prop="name" :label="$t('developer.name')" show-overflow-tooltip></el-table-column>
      <el-table-column prop="openType" :label="$t('developer.openType')" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="scope.row.openType === 1">mqtt</span>
          <span v-if="scope.row.openType === 2">http</span>
        </template>
      </el-table-column>
      <el-table-column prop="requestParam" :label="$l('developer.appKey','请求参数')" show-overflow-tooltip></el-table-column>
      <el-table-column prop="userAccount" :label="$l('subPlatform.userAccount', '用户名')" align="center" width="150"></el-table-column>
      <el-table-column
        prop="username"
        :label="$l('subPlatform.userName', '姓名')"
        align="center"
        width="120"
        show-overflow-tooltip></el-table-column>
      <el-table-column
        prop="userContact"
        :label="$l('subPlatform.userContact', '联系方式')"
        align="center"
        width="120"
        show-overflow-tooltip></el-table-column>
      <el-table-column prop="status" :label="$t('developer.status')" width="80" align="center">
        <template slot-scope="scope">
          <el-tag :type="scope.row.status === 1 ? 'success' : 'info'">
            {{ scope.row.status === 1 ? $t("developer.activate") : $t("developer.close") }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        v-if="editAuth"
        :label="$t('common.operation')"
        width="160"
        align="center"
        fixed="right">
        <template slot-scope="scope">
          <el-button type="text" @click="handleEdit(scope.row)">{{ $t("common.edit") }}</el-button>
          <el-button type="text" @click="handleStatus(scope.row)">
            {{ scope.row.status === 1 ? $t("developer.close") : $t("developer.activate") }}
          </el-button>
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">
            {{ $t("common.delete")
            }}
          </el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-dialog ref="editPage" @save-success="getList(-1)"></edit-dialog>
  </div>
</template>
<script>
  import EditDialog from "./DeveloperEdit.vue";

  const moduleName = "developers";

  export default {
    components: { EditDialog },

    data() {
      return {
        editAuth: true,
        loading: false,
        currentPage: 1,
        filter: "",
        total: 0,
        search: {
          filter: "",
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.loading = false;
        this.$refs.vmTable.getList(pageNum);
      // this.currentPage = pageIndex;
      // this.loading = true;
      // let params = {
      //   pageIndex,
      //   filter: this.filter,
      // };
      // this.$api.getList(moduleName, params).then(res => {
      //   this.loading = false;
      //   let data = res.data;
      //   this.tableData = data.list;
      //   this.total = data.total;
      // }).catch(() => {
      //   this.loading = false;
      //   this.$message.error(this.$t("common.tip.getDataError"));
      // });
      },
      handleAdd() {
        this.$refs.editPage.open(0);
      },
      handleEdit(row) {
        this.$refs.editPage.open(row.id);
      },
      handleStatus(row) {
        row.status = row.status === 0 ? 1 : 0;
        this.$axios.put("developers/" + row.id + "/status/" + row.status).then(res => {
          if (row.status === 1) {
            this.$message.success(this.$t("developer.tip.activateSucceed"));
          } else {
            this.$message.success(this.$t("developer.tip.closeSucceed"));
          }
        }).catch(() => {
          this.$message.error(this.$t("common.tip.operationFailure"));
        });
      },
      handleDelete(row) {
        this.$confirm(this.$t("common.delete") + " " + this.$t("developer.no") + " " + row.appKey + "，" + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$api.deleteById(moduleName, row.id).then(res => {
            this.getList(1);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          }).catch(() => {
            this.$message.error(this.$t("common.tip.deleteError"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped></style>
