<template>
  <div class="vm-editor-wrapper">
    <div ref="editor" class="vm-editor-editor" style="min-height: 300px"></div>
  </div>
</template>

<script>
  import E from "wangeditor";
  import ossUtil from "@/util/ossUtil";

  export default {
    data() {
      return {
        editor: null,
      };
    },
    mounted() {
      this.initEditor();
    },
    beforeDestroy() {
      if (this.editor) {
        this.editor.destroy();
      }
    },
    methods: {
      initEditor() {
        let editor = new E(this.$refs.editor);
        this.editor = editor;
        editor.config.debug = true;
        editor.config.customUploadImg = function(resultFiles, insertImgFn) {
          ossUtil.upload(resultFiles[0], false, true).then(res => {
            insertImgFn(res.url);
          });
        };
        editor.create();
      },
      setContent(content) {
        this.editor.txt.html(content);
      },
      getContent() {
        return this.editor.txt.html();
      },
    },
  };
</script>

<style lang="scss" scoped>
.vm-editor-wrapper {
  position: relative;
  z-index: 1000;
}

</style>
<style>
.w-e-text-container {
  height: 600px!important;
}
</style>
