<template>
  <div class="parentLevel">
    <div class="kind1">
      <el-table v-loading="loading" :data="tableData" border>
        <el-table-column prop="name" :label="$t('sysItem.name')">
          <template slot-scope="scope">
            <el-button style="width: 100%" :type="scope.row.no === showValue ? 'primary' : ''" @click="clickTab(scope.row)">
              {{ scope.row.name }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-show="showValue.startsWith('22')" class="kind3">
      <div>
        <el-button type="success" :loading="loadCacheDataLoading" @click="loadCacheData">
          重新初始化电梯缓存
        </el-button>
      </div>
    </div>
    <div v-show="showValue.startsWith('44')" style="margin-left: 100px;">
      <maint-setting-list system-code="admin"></maint-setting-list>
    </div>
    <div v-show="showValue.startsWith('5555')" class="kind2">
      <fault-reason-list></fault-reason-list>
    </div>
    <edit-dialog ref="editPage" @save-success="getSysItemList"></edit-dialog>
  </div>
</template>
<script>
  import EditDialog from "./SysItemEdit.vue";
  import MaintSettingList from "@/views/newMaintWorkOrder/maintSetting/maintSettingList";
  import FaultReasonList from "../faultReason/FaultReasonList";

  const moduleName = "sysItems";

  export default {
    components: { FaultReasonList, MaintSettingList, EditDialog },
    data() {
      return {
        editAuth: this.$auth(41),
        loading: false,
        loading2: false,
        contentLoading: false,
        submitLoading: false,
        saveDisabled: false,
        tableData: [],
        showValue: "",
        loadCacheDataLoading: false,
      };
    },
    mounted() {
      this.getList();
    },
    methods: {
      getList() {
        this.loading = true;
        this.$api.getList(moduleName).then(response => {
          this.loading = false;
          this.tableData = response.data;
          this.showValue = this.tableData[0].no;
        }).catch(() => {
          this.loading = false;
          this.$message.error(this.$t("common.tip.getDataError"));
        });
      },
      handleEdit(row) {
        this.$refs.editPage.open(row.id);
      },
      clickTab(row) {
        this.showValue = row.no;
      },
      loadCacheData() {
        this.loadCacheDataLoading = true;
        this.$api.getData("sysItems/loadCacheData").then(() => {
          this.loadCacheDataLoading = false;
          this.$message.success(this.$t("common.tip.operationSuccess"));
        }).catch(() => {
          this.loadCacheDataLoading = false;
          this.$message.error(this.$t("common.tip.operationFailure"));
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
.parentLevel {
  display: flex;

  .kind1 {
    width: 200px;
    margin-bottom: 40px;
  }

  .kind2 {
    flex: 1;
    margin-left: 20px;
  }

  .kind3 {
    flex: 1;
    margin: 0 20px;
  }

  .el-table {
    border-radius: 12px;
  }
}
</style>
